import { Fragment, useEffect,useState } from "react";
import Layout from "../../../layouts/Layout";
import { useLocation, useNavigate } from "react-router-dom";
const successImg = "/assets/demos/success.png";
const failedImage = "/assets/demos/failure.png";

const Result = () => {   

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const [showMenu, setShowMenu] = useState(false);

  const onGotoWelcome = () => {
    navigate(process.env.PUBLIC_URL + "/shop/welcome");
  };
  const trigerMenu = () =>{
    setShowMenu(!showMenu)
  }
  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className=" shop-cart-main-area">
          <div className="header-backgrund">
            <div className="container">
              <div className="header">
                <img className="logo" alt="" src="/assets/img/cart/logo_1.png"/>
                <div className="header-content">
                  <div className="top-row">
                    <div>CONTACT US</div>
                    <div className="line"></div>
                    <div>GIFTING</div>
                    <div className="line"></div>
                    <div>THE DROP</div>
                  </div>
                  <div className="middle-row">
                    <div class="search-container">
                      <input 
                        type="text" 
                        id="search-input" 
                        class="search-input" 
                        style={{backgroundImage: "url(" + "/assets/img/cart/search_white.png" + ")" ,borderRadius:'8px'}}
                        placeholder="Search brand, type, variety or region"/>
                    </div>
                    <div className="cart">
                      <img alt="" width={20} src="/assets/img/cart/cart_white.png"/>
                      <div className="cart-text">Cart</div>
                    </div>
                  </div>
                  <div className="bottom-row">
                    <div>BEER</div>
                    <div className="line"></div>
                    <div>SPARKLING</div>
                    <div className="line"></div>
                    <div>WHITE</div>
                    <div className="line"></div>
                    <div>RED</div>
                    <div className="line"></div>
                    <div>SPIRITS</div>
                    <div className="line"></div>
                    <div>GIFTS</div>
                    <div className="line"></div>
                    <div>REFRESHMENTS</div>
                    <div className="line"></div>
                    <div>LOW / NO ALC</div>
                  </div>
                  <div className="bottom-menu">
                    <img onClick={trigerMenu} className="menu-icon" alt="" src="/assets/img/cart/menu.png"/>
                    {showMenu&& <div className="sub-menu">
                      <div>BEER</div>
                      <div className="line"></div>
                      <div>SPARKLING</div>
                      <div className="line"></div>
                      <div>WHITE</div>
                      <div className="line"></div>
                      <div>RED</div>
                      <div className="line"></div>
                      <div>SPIRITS</div>
                      <div className="line"></div>
                      <div>GIFTS</div>
                      <div className="line"></div>
                      <div>REFRESHMENTS</div>
                      <div className="line"></div>
                      <div>LOW / NO ALC</div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div> 
          {state?.result?<div className="mt-100 container justify-content-center align-items-center  result-modal">
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <img src={
                process.env.PUBLIC_URL +
                successImg
              } width={70}></img>
            </div>
            <div className="row justify-content-center align-items-center pt-30" style={{textAlign: "center"}}>
              <h4 style={{fontSize:"22px", lineHeight:"33px"}}>Your order is being processed</h4>
            </div>
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <button className="btn-in-shop" onClick={onGotoWelcome} title="Verify Age">SEARCH ANOTHER PRODUCT</button>
            </div>    
          </div>:
          <div className="mt-100 container justify-content-center align-items-center  result-modal">
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <img src={
                process.env.PUBLIC_URL +
                failedImage
              } width={70}></img>
            </div>
            <div className="row justify-content-center align-items-center pt-30" style={{textAlign: "center"}}>
              <h4 style={{fontSize:"22px", lineHeight:"33px"}}>You are not eligible to buy this product</h4>
            </div>
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <button className="btn-in-shop" onClick={onGotoWelcome}  title="Verify Age">SEARCH ANOTHER PRODUCT</button>
            </div>    
          </div>}
        </div>
      </Layout>
    </Fragment>
  );
};

export default Result;
