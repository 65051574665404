import Grid from '@mui/material/Grid';

const TopSection = ({handleExplore, handleContact}) => {

  return (     
      <div className="top-section">
        <img className="top-left-logo" alt="" src="/assets/img/home/leftLogo.png" />

        <div className="top-right-logo">
          <img className=""  width={24} height={24} alt="" src="/assets/img/home/blackIcon.png" />
          <div className="right-logo-text">POWERED BY SHARERING</div>
        </div>
      </div>
      
  )
}
export default TopSection;
