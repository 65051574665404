import {  useEffect } from "react";

const QrcodeSuccess = ({number, handleClick, date, time}) => {

  useEffect(() => {
  }, []);
  const handleBtnClick = (e) => {
    handleClick(e) 
  };

  return ( 
    <div className="content">
      <div className="left-section">
        <img alt="" src="/assets/img/endeavourHotel/logo.png" className="logo-img"/>
        <div className="left-section-content">
          <div className="top-border">
            <div className="border1"/>
            <div className="border2"/>
            <div className="border1"/>
          </div>
          <div className="title"
            style={{ fontWeight:300}}
            >
            {"Thank you"}
          </div> 
          <img alt="" src="/assets/img/endeavourHotel/bottomLine.png" className="bottom-line" />
          <div className="desscription"
            style={{ fontWeight:300}}
            >
            {"We hope you enjoy your stay."}
          </div>   
          <div className="bottom-logo">
            <img alt="" width={26} src="/assets/img/hotel/logo.png"/>
            <div style={{marginTop:'10px'}}>
              Powered by ShareRing
            </div>
          </div>
          <div className="bottom-border"/>
        </div>
      </div>  
      <div className="right-section">
        <div className="calendar">
          <img alt="" width={24} src="/assets/img/endeavourHotel/calendar.png" />
          <div className="calendar-text">{date}</div>
        </div>
        <div className="time">
          <img alt="" width={24} src="/assets/img/endeavourHotel/time.png" />
          <div className="time-text">{time}</div>
        </div>
        <div className="right-section-border">
          <div className="right-section-content">
            <div className="title">
              Checked in successfully
            </div> 
            <button className="btn-gradient"
              onClick={e => handleBtnClick(e)}>
              Back to home screen
            </button>
            <div className="desscription">
              Redirecting to home screen after {number} seconds
            </div> 
          </div>
        </div>   
      </div>   
    </div>     
  )
}
export default QrcodeSuccess;
