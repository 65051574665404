
const Welcome = () => {

  return (  
    <div className="bottom-logo-line">
      {/* <img className="bottom-logo" alt="" src={'/assets/img/home/bottomLogo_2.png'} />  */}
      <div>© Endeavor Group 2023</div>
    </div>     
  )
}

export default Welcome;
