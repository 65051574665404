import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/main/home/Home";
import EndeavourHotelWelcome from "./pages/main/endeavourHotel/Welcome";
import EndeavourHotelResult from "./pages/main/endeavourHotel/Result";
import EndeavourAgeWelcome from "./pages/main/endeavourAge/Welcome";
import EndeavourAgeResult from "./pages/main/endeavourAge/Result";
import EndeavourShopWelcome from "./pages/main/endeavourShop/Welcome";
import EndeavourShopResult from "./pages/main/endeavourShop/Result";
import EndeavourGamingAgeWelcome from "./pages/main/endeavourGamingAge/Welcome";
import EndeavourGamingAgeResult from "./pages/main/endeavourGamingAge/Result";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<Home />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/hotel/welcome"}
              element={<EndeavourHotelWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/hotel/result"}
              element={<EndeavourHotelResult />}
            />
            
            <Route
              path={process.env.PUBLIC_URL + "/age/welcome"}
              element={<EndeavourAgeWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/game/result"}
              element={<EndeavourGamingAgeResult />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/game/welcome"}
              element={<EndeavourGamingAgeWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/age/result"}
              element={<EndeavourAgeResult />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/shop/welcome"}
              element={<EndeavourShopWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shop/result"}
              element={<EndeavourShopResult />}
            />
            {/* <Route path="*" element={<NotFound />} /> */}
            <Route path="*" element={<Navigate to={`/`} />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;