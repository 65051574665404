import { Fragment,  } from "react";
import Box from '@mui/material/Box';
import TopSection from "../../../components/home/TopSection"
import MiddleSection from "../../../components/home/MiddleSection"
import BottomSection from "../../../components/home/BottomSection"
import Bottom from "../../../components/home/Bottom"
import ScrollToTop from "../../../components/scroll-to-top"

const contactUrl = 'https://sharering.network/contact'
const googleUrl = 'https://play.google.com/store/apps/details?id=network.sharering.identifime&hl=en_US&pli=1'
const appleUrl = 'https://apps.apple.com/sa/app/identifi-me/id6448408441'

const demos = [
  {img:'/assets/img/home/hotelCheck-In_2.png', url:'/hotel/welcome', title:'Hotel Check-In',description:'Automate your check-in processes and reduce waiting time to less than a minute.'},
  {img:'/assets/img/home/pointOfSaleAgeVerification.png', url:'/age/Welcome', title:'Point of Sale Age Verification',description:'Verify your age to purchase age-restricted products.'},
  {img:'/assets/img/home/purchaseAlcohol_2.png', url:'/shop/welcome', title:'Online Alcohol Purchase',description:'Complete age verification on age-restricted products.'},
  {img:'/assets/img/home/gamingAgeVerification.png', url:'/game/welcome', title:'Gaming Age Verification',description:'Verify your age to play age-restricted games.'},
  
]

const Home = ({ }) => {

  const handleClick = (e,item) => {
    window.open(process.env.PUBLIC_URL + item.url, "_blank");    
  };

  const handleExplore = () => {
    window.scrollTo(0, document.documentElement.scrollHeight);
  }

  const handleContact = () => {   
    window.open(process.env.PUBLIC_URL + contactUrl, "_blank");
  };  
  
  const onClickApple = () => {   
    window.open(process.env.PUBLIC_URL + appleUrl, "_blank");
  };  
  
  const onClickGoogle = () => {   
    window.open(process.env.PUBLIC_URL + googleUrl, "_blank");
  };

  return (
    <Fragment>
      <Box className="home-area">
        <div className="patterns">
          <img className="leftPattern" alt="" src="/assets/img/home/leftPattern.png"/>
          <img className="leftBottomPattern" alt="" src="/assets/img/home/leftBottomPattern.png"/>
        </div>
        <TopSection
          handleExplore={handleExplore}
          handleContact={handleContact}
        />
        <BottomSection
          onClickDemo={handleClick}
          demos = {demos}
        />
        <ScrollToTop/>
        <Bottom/>
      </Box>
    </Fragment>
  )
}
export default Home;
