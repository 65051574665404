import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import WelcomeScreen from "../../../components/endeavourHotel/Welcome";
import WelcomeMobile from "../../../components/endeavourHotel/WelcomeMobile";
import APIServices from "../../../services/graphql/query";
import { Link, useNavigate } from "react-router-dom";

import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "6540c77b1b89000055e61da9" : "6553400b0635c10060dfdd7d";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const Welcome = () => {
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState("");      
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [deeplinkContent, SetDeeplinkContent] = useState("");      
  const [qrcodeShortLink, setQrShortLink] = useState("");      
  const navigate = useNavigate();  
  const [currentDate, setCurrentDate] = useState();
  const [currentTime, setCurrentTime] = useState();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    // Add a resize event listener when the component mounts
    window.addEventListener('resize', handleResize);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs once after mount

  useEffect(() => {
    getCurrentDateTime()
    const intervalId = setInterval(() => {
      getCurrentDateTime()
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getCurrentDateTime = () => {
    const currentDate = new Date();
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = currentDate.toLocaleTimeString('en-US', options);
    setCurrentDate(formattedDate)
    setCurrentTime(formattedTime)
  };

  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {       
      console.log('getQRRequestItems response', params, response);     
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let result = JSON.parse(data).qrRes;                          
            console.log('result', result);
            //sessionId = ""                                    
            if (result){       
              const name = result.split("Name: ")[1].split("\n")[0];
              const passport_number = result.split("Passport No: ")[1].split("\n")[0];
              const passport_country = result.split("Passport Country: ")[1].split("\n")[0];
              const date_of_birth = result.split("Date of Birthday: ")[1].split("\n")[0];
              const personal_phone_number = result.split("Phone number: ")[1].split("\n")[0];
              const email_address = result.split("Email: ")[1].split("\n")[0];
              navigate(process.env.PUBLIC_URL + "/hotel/result",{state: {name, passport_number, passport_country, date_of_birth, personal_phone_number, email_address}});
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }

          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="endeavour-hotel-area" 
          style={{ backgroundImage: "url('/assets/img/endeavourHotel/background.png')", position:"absolute"}}>   
          {screenWidth>1050?
            <WelcomeScreen 
              qrCode={qrCode}
              time={currentTime}
              date={currentDate}
              goDeepLink={goDeepLink}
            />:
            <WelcomeMobile 
              qrCode={qrCode}
              time={currentTime}
              date={currentDate}
              goDeepLink={goDeepLink}
            />
          }
        </div>
      </Layout>
    </Fragment>
  )
}

export default Welcome;
