import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import WelcomeScreen from "../../../components/hotel/Welcome";
import WelcomeMobile from "../../../components/hotel/WelcomeMobile";
import APIServices from "../../../services/graphql/query";
import { Link, useNavigate } from "react-router-dom";

import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "6565c28e81738c00554d0150" : "656698eabeb82000555694e5";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const Welcome = () => {
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState("");      
  const [deeplinkContent, SetDeeplinkContent] = useState("");      
  const [qrcodeShortLink, setQrShortLink] = useState("");    
  
  const navigate = useNavigate();  

  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {       
      console.log('getQRRequestItems response', params, response);     
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let result = JSON.parse(data).qrRes;                          
            console.log('result', data);
            //sessionId = ""                                    
            if (result){       
              navigate(process.env.PUBLIC_URL + "/game/result", {state: {result}});
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }

          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="gaming-age-area" 
          style={{
            backgroundImage: "url('/assets/img/endeavourAge/background_gaming_age_blue.png')",
            position:"absolute",
          }}
          > 
            <div className="content">
              <div className="qr-code-section">
                <div className="description">
                    Please scan the QR code to prove you are over 18
                </div>
                <div className="qrcode-section">
                  <div style={{ width: "264px", height: "264px", margin: "0px", borderWidth: "0px", padding: "4px", curosr: "pointer", background: "white" }} onClick={goDeepLink}>
                    {qrcodeShortLink&&<QRCode size={256} value={qrcodeShortLink} viewBox={`0 0 256 256`} />}
                  </div>
                </div>
              </div>
              {/* <div className="bottom-logo">
                <img alt="" width={32} src="/assets/img/hotel/logo.png"/>
                <div className="logo-text">
                    Powered by ShareRing
                </div>
              </div> */}
            </div>
          </div>
      </Layout>
    </Fragment>
  )
}
export default Welcome;
