import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

const Welcome = ({qrCode}) => {

  useEffect(() => {
  }, []);

  return (
    <div className="content" style={{paddingLeft:0, justifyContent: 'center'}}>
      <div className="left-section-mobile">
        <img alt="" src="/assets/img/endeavourHotel/logo.png" className="logo-img"/>
        <div className="left-section-content">
          <div className="top-border">
            <div className="border1"/>
            <div className="border2"/>
            <div className="border1"/>
          </div>
          <div className="title"
            style={{ fontWeight:300}}
            >
            {"Welcome"}
          </div> 
          <img alt="" src="/assets/img/endeavourHotel/bottomLine.png" className="bottom-line" />
          <div className="desscription"
            style={{ fontWeight:300}}
            >
            {" To check in to your room, please "}
            <span style={{ fontWeight:600 }}>
              {'scan this QR code'}
            </span>
          </div>   
          <div className="bottom-logo">
            <img  alt="" width={26} src="/assets/img/hotel/logo.png"/>
            <div style={{marginTop:'10px'}}>
              Powered by ShareRing
            </div>
          </div>
          <div className="right-section">
            {qrCode&&<div className="qrcode-section">
              <QRCode size={240} value={qrCode} viewBox={`0 0 256 256`} /> 
            </div> }
          </div>
          <div className="bottom-border"/>
        </div>  
      </div>  
    </div>     
  )
}

export default Welcome;
