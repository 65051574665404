import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { Modal } from "react-bootstrap";
import { useNavigate,useLocation } from "react-router-dom";

const successIcon = "/assets/demos/success.png";
const failureIcon = "/assets/demos/failure.png";

function Result () {
  const location = useLocation();
  const data = location.state;

  const [inputValue, setInputValue] = useState(data.result.split("Your spending limit is ")[1]);   
  const navigate = useNavigate(); 

  const onSubmmit = () => { 
    navigate(process.env.PUBLIC_URL + "/game/welcome");
  }
  const onChangeInput = (e)=>{
    setInputValue(e.target.value)
  }
  const onBackToHome = (e)=>{
    navigate(process.env.PUBLIC_URL + "/game/welcome");
  }
  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="gaming-age-area" 
          style={{
            backgroundImage: "url('/assets/img/endeavourAge/background_gaming_age_blue.png')",
            position:"absolute",
          }}>
          <div className="result-content">
            {data?.result.includes('Thank you, your age has been verified') ? <div className="result-modal-body">
              <div style={{width: "100%"}}>
                <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
                  <img src={`${process.env.PUBLIC_URL}${successIcon}`} width={50}/>
                </div>
                <h4 style={{color:'#333333', marginTop:"20px", fontSize: "24px", textAlign: "center", lineHeight:'32px'}}>{`Age verified successfully.`}</h4>
                <h4 style={{color:'#333333', fontSize: "24px", textAlign: "center", lineHeight:'32px'}}>{`Enjoy your game.`}</h4>
              </div>
              <div className="">
                <div style={{marginTop:"10px", color: "#666666", fontSize: "14px", lineHeight:'20px',fontWeight:300}}>Spending Limit</div>
                <input
                  style= {{borderRadius:'8px',  background:'#ECEFFF', height:'38px', marginTop:"10px"}}
                  className="input-text"
                  onChange={(e) => {onChangeInput(e) }}
                  disabled={true}
                  value={inputValue}
                />
              </div>          
              <div className="result-modal-submit" 
                style={{display:"flex", justifyContent:'center' }}>
                <button className="result-modal-submit-btn" onClick={() => onSubmmit()}>Submit</button>
              </div>
            </div>:
            <div className="result-modal-body result-failue-modal-body">
              <div style={{width: "100%"}}>
                <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
                  <img src={`${process.env.PUBLIC_URL}${failureIcon}`} width={50}/>
                </div>
                <h4 style={{color:'#333333', marginTop:"20px", fontSize: "24px", textAlign: "center", lineHeight:'32px'}}>{`Age verified failed.`}</h4>
                <h4 style={{color:'#333333', fontSize: "24px", textAlign: "center", lineHeight:'32px'}}>{`You are not eligible to play this game.`}</h4>
              </div>         
              <div className="result-modal-submit" 
                style={{display:"flex", justifyContent:'center' }}>
                <button className="result-modal-submit-btn" onClick={() => onBackToHome()}>Back to Home screen</button>
              </div>
            </div>}
            {/* <div className="bottom-logo">
              <img alt="" width={32} src="/assets/img/hotel/logo.png"/>
              <div className="logo-text">
                  Powered by ShareRing
              </div>
            </div> */}
          </div>
          
        </div>
      </Layout>
    </Fragment>
  );
}
export default Result;
