import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

const Welcome = ({qrCode, date, time}) => {

  useEffect(() => {
  }, []);

  return (  
    <div className="content">
      <div className="left-section">
        <img alt="" src="/assets/img/endeavourHotel/logo.png" className="logo-img"/>
        <div className="left-section-content">
          <div className="top-border">
            <div className="border1"/>
            <div className="border2"/>
            <div className="border1"/>
          </div>
          <div className="title"
            style={{ fontWeight:300}}
            >
            {"Welcome"}
          </div> 
          <img alt="" src="/assets/img/endeavourHotel/bottomLine.png" className="bottom-line" />
          <div className="desscription"
            style={{ fontWeight:300}}
            >
            {" To check in to your room, please "}
            <span style={{ fontWeight:600 }}>
              {'scan this QR code'}
            </span>
          </div>   
          <div className="bottom-border"/>
          <div className="bottom-logo">
            <img  alt="" width={26} src="/assets/img/hotel/logo.png"/>
            <div style={{marginTop:'10px'}}>
              Powered by ShareRing
            </div>
          </div>
        </div>  
      </div>  
      <div className="right-section">
        <div className="calendar">
          <img alt="" width={24} src="/assets/img/endeavourHotel/calendar.png" />
          <div className="calendar-text">{date}</div>
        </div>
        <div className="time">
          <img alt="" width={24} src="/assets/img/endeavourHotel/time.png" />
          <div className="time-text">{time}</div>
        </div>
        <div className="right-section-border">       
          {qrCode&&<div className="qrcode-section">
            <QRCode size={240} value={qrCode} viewBox={`0 0 256 256`} /> 
          </div> }
        </div>   
      </div>
    </div>     
  )
}

export default Welcome;
